import React from "react";
import Helmet from "react-helmet";

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: { content: string; name: string }[];
  title: string;
  image?: string;
}

const SEO = ({
  description = "",
  lang = "en",
  meta = [],
  title,
  image = null,
}: SEOProps) => {
  const metaDescription = description.slice(
    0,
    description.lastIndexOf(" ", 160)
  );

  const isBrowser = typeof window !== "undefined";

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: image,
        },
        {
          name: `og:image`,
          content: image,
        },
        {
          name: `twitter:image`,
          content: image,
        },
      ].concat(meta)}
    >
      {isBrowser && (
        <script
          async
          defer
          data-domain="stereamedicaltravel.it"
          src="https://plausible.io/js/plausible.js"
        ></script>
      )}
    </Helmet>
  );
};

export default SEO;
