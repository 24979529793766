import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  FaBars,
  FaCommentDots,
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
  FaTimes,
} from "react-icons/fa";

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuShown, setSubmenuShown] = useState(null);

  return (
    <>
      <div
        id="mobile-menu"
        className={`fixed z-50 w-full h-screen bg-white lg:hidden flex flex-col justify-between ${
          menuOpen ? "open" : ""
        }`}
      >
        <div className="sterea-center-column-wrapper">
          <div className="sterea-center-column py-md lg:py-lg px-min flex items-center justify-between">
            <div className="lg:hidden text-3xl text-sterea-dark-blue">
              <FaTimes
                className="cursor-pointer"
                onClick={() => setMenuOpen(false)}
              />
            </div>
            <Link to="/">
              <StaticImage
                quality={90}
                src="../images/sterea_medicina_e_viaggi_logo.png"
                placeholder="none"
                loading="eager"
                layout="fixed"
                height={50}
                alt="Logo con la scritta Sterea Medicina e Viaggi"
              />
            </Link>
            <div className="sterea-yellow-button hidden lg:block">
              Contattaci
            </div>
            <div className="lg:hidden text-3xl text-sterea-dark-blue opacity-0">
              <FaTimes />
            </div>
          </div>
        </div>
        <div className="sterea-center-column-wrapper text-sterea-blue">
          <div className="sterea-center-column py-6 text-xl flex flex-col px-min flex justify-between">
            <div
              className="cursor-pointer mb-3 flex justify-between items-center"
              onClick={() =>
                setSubmenuShown(
                  submenuShown == "turismo-medico" ? null : "turismo-medico"
                )
              }
            >
              <span>Turismo Medico</span>
              <span>{submenuShown == "turismo-medico" ? "-" : "+"}</span>
            </div>
            {submenuShown == "turismo-medico" && (
              <>
                <Link
                  to="/turismo-medico"
                  className="ml-6 mb-3"
                  onClick={() => setMenuOpen(false)}
                >
                  Cos'è il turismo medico
                </Link>
                <Link
                  to="/perche-all-estero"
                  className="ml-6 mb-3"
                  onClick={() => setMenuOpen(false)}
                >
                  Perché all'estero
                </Link>
                <Link
                  to="/chi-siamo"
                  className="ml-6 mb-3"
                  onClick={() => setMenuOpen(false)}
                >
                  Chi siamo
                </Link>
              </>
            )}
            <div
              className="cursor-pointer mb-3 flex justify-between items-center"
              onClick={() =>
                setSubmenuShown(
                  submenuShown == "partners" ? null : "partners"
                )
              }
            >
              <span>Partners</span>
              <span>{submenuShown == "partners" ? "-" : "+"}</span>
            </div>
            {submenuShown == "partners" && (
              <>
                <Link
                  to="/specialisti"
                  className="ml-6 mb-3"
                  onClick={() => setMenuOpen(false)}
                >
                  Specialisti
                </Link>
                <Link
                  to="/cliniche"
                  className="ml-6 mb-3"
                  onClick={() => setMenuOpen(false)}
                >
                  Cliniche
                </Link>
              </>
            )}
            <Link
              to="/interventi"
              className="mb-3"
              onClick={() => setMenuOpen(false)}
            >
              Interventi
            </Link>
            <Link
              to="/destinazioni"
              className="mb-3"
              onClick={() => setMenuOpen(false)}
            >
              Destinazioni
            </Link>
            <Link
              to="/galleria"
              className="mb-3"
              onClick={() => setMenuOpen(false)}
            >
              Galleria
            </Link>
            <Link
              to="/blog"
              className="mb-3"
              onClick={() => setMenuOpen(false)}
            >
              Blog
            </Link>
            <Link to="/contatti" onClick={() => setMenuOpen(false)}>
              Contattaci
            </Link>
            {/* <Link to="/contatti" onClick={() => setMenuOpen(false)}>
              <div className="sterea-yellow-button">
                Contattaci
              </div>
            </Link> */}
          </div>
        </div>
        <div></div>
      </div>
      <header className="sterea-center-column-wrapper">
        <div className="sterea-center-column py-6 px-min flex items-center justify-between">
          <div className="lg:hidden text-2xl px-sm py-sm text-sterea-dark-blue">
            <FaBars
              className="cursor-pointer"
              onClick={() => setMenuOpen(true)}
            />
          </div>
          <Link to="/">
            <StaticImage
              quality={90}
              src="../images/sterea_medicina_e_viaggi_logo.png"
              placeholder="none"
              loading="eager"
              layout="fixed"
              height={50}
              alt="Logo con la scritta Sterea Medicina e Viaggi"
            />
          </Link>
          <div className="hidden lg:flex">
            <div className="relative group mr-4">
              <Link to="/turismo-medico">Turismo Medico</Link>
              <div className="w-full hidden group-hover:block border-b border-black"></div>
              <div className="absolute hidden group-hover:block w-max z-50 flex flex-col">
                <div className="h-3"></div>
                <div className="filter drop-shadow-md">
                  <Link
                    to="/turismo-medico"
                    className="block bg-white hover:bg-sterea-light-gray px-md w-full text-sterea-dark-gray hover:text-black text-sm min-w-max py-md"
                  >
                    Cos'è il turismo medico
                  </Link>
                  <Link
                    to="/perche-all-estero"
                    className="block bg-white hover:bg-sterea-light-gray px-md w-full text-sterea-dark-gray hover:text-black text-sm min-w-max py-md"
                  >
                    Perché all'estero
                  </Link>
                  <Link
                    to="/chi-siamo"
                    className="block bg-white hover:bg-sterea-light-gray px-md w-full text-sterea-dark-gray hover:text-black text-sm min-w-max py-md"
                  >
                    Chi siamo
                  </Link>
                </div>
              </div>
            </div>
            <div className="relative group mr-4">
              <span>Partners</span>
              <div className="w-full hidden group-hover:block border-b border-black"></div>
              <div className="absolute hidden group-hover:block w-max z-50 flex flex-col">
                <div className="h-3"></div>
                <div className="filter drop-shadow-md">
                  <Link
                    to="/specialisti"
                    className="block bg-white hover:bg-sterea-light-gray px-md w-full text-sterea-dark-gray hover:text-black text-sm min-w-max py-md"
                  >
                    Specialisti
                  </Link>
                  <Link
                    to="/cliniche"
                    className="block bg-white hover:bg-sterea-light-gray px-md w-full text-sterea-dark-gray hover:text-black text-sm min-w-max py-md"
                  >
                    Cliniche
                  </Link>
                </div>
              </div>
            </div>
            <div className="group mr-4">
              <Link to="/interventi">Interventi</Link>
              <div className="w-full hidden group-hover:block border-b border-black"></div>
            </div>
            <div className="group mr-4">
              <Link to="/destinazioni">Destinazioni</Link>
              <div className="w-full hidden group-hover:block border-b border-black"></div>
            </div>
            <div className="group mr-4">
              <Link to="/galleria">Galleria</Link>
              <div className="w-full hidden group-hover:block border-b border-black"></div>
            </div>
            <div className="group mr-4">
              <Link to="/blog">Blog</Link>
              <div className="w-full hidden group-hover:block border-b border-black"></div>
            </div>
          </div>
          <Link to="/contatti">
            <div className="hidden lg:block sterea-yellow-button">
              Contattaci
            </div>
            <div className="opacity-0 lg:opacity-1 lg:hidden bg-sterea-yellow text-white text-2xl px-sm py-sm rounded-full">
              <FaCommentDots className="lg:hidden" />
            </div>
          </Link>
        </div>
      </header>

      <main>{children}</main>

      <footer>
        <div className="sterea-center-column-wrapper">
          <div className="sterea-center-narrow-column flex flex-col">
            <div className="py-lg px-min flex flex-wrap justify-start lg:justify-center">
              <div className="w-1/2 lg:w-auto lg:mr-24 mb-6 lg:mb-0">
                <h4 className="font-medium mb-2 lg:mb-4">Sterea</h4>
                <Link to="/chi-siamo" className="block">
                  Chi siamo
                </Link>
                <Link to="/codice-etico" className="block">
                  Codice Etico
                </Link>
                {/* <li>
                  <a href="#">FAQ</a>
                </li> */}
                <Link to="/blog" className="block">
                  Blog
                </Link>
                <Link to="/contatti" className="block">
                  Contatti
                </Link>
              </div>
              <div className="w-1/2 lg:w-auto lg:mr-24 mb-6 lg:mb-0">
                <h4 className="font-medium mb-2 lg:mb-4">Viaggi Medicali</h4>
                <Link to="/destinazioni" className="block">
                  Destinazioni
                </Link>
                <Link to="/galleria" className="block">
                  Galleria
                </Link>
                <Link to="/interventi" className="block">
                  Interventi
                </Link>
                <Link to="/galleria" className="block">
                  Galleria
                </Link>
              </div>
              <div className="w-1/2 lg:w-auto">
                <h4 className="font-medium mb-2 lg:mb-4">Seguici sui social</h4>
                <a
                  href="https://www.facebook.com/sterea.estetica/"
                  className="flex items-center"
                  target="_blank"
                >
                  <FaFacebookF className="text-lg mr-2" /> Facebook
                </a>
                <a
                  href="https://www.instagram.com/sterea.chirurgiaplastica/"
                  className="flex items-center"
                  target="_blank"
                >
                  <FaInstagram className="text-lg mr-2" /> Instagram
                </a>
                <a
                  href="https://www.pinterest.it/stereamedicaltravel/"
                  className="flex items-center"
                  target="_blank"
                >
                  <FaPinterestP className="text-lg mr-2" /> Pinterest
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="sterea-center-column-wrapper bg-sterea-light-gray py-3 px-min">
          <div className="sterea-center-column flex flex-col lg:flex-row justify-between text-sm text-sterea-lily">
            <div>© {new Date().getFullYear()} Sterea Medicina e Viaggi</div>
            <div className="flex flex-wrap mt-3 lg:mt-0">
              <Link to="/terms" className="mr-4">
                Termini e condizioni
              </Link>
              <Link to="/privacy-policy" className="mr-4">
                Privacy policy
              </Link>
              <a href="https://www.iubenda.com/privacy-policy/35473678/cookie-policy">
                Cookie policy
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
